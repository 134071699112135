.about-container h1 {
  text-transform: uppercase;
  font-size: 3.2rem;
  padding-left: 10px;
  margin-bottom: 15px;

  border-left: 4px solid var(--primary);
}

.about-container p {
  font-size: 1.6rem;
  max-width: 500px;
  margin: 20px 0;
  line-height: 2.4rem;
}

.about-container {
  width: 90%;
  max-width: 1200px;
  margin: 100px auto;
}

.about-content {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.about-content img {
  width: 512px;
  height: 100%;
}

.about-text {
  margin: auto 0;
  text-align: justify;
}

.btn {
  justify-content: center;
  align-items: center;
  padding: 1.5rem;
  display: inline-block;
  text-decoration: none;
  font-size: 1.6rem;
  text-transform: uppercase;
  font-weight: bold;
  width: 200px;
  height: 50px;
  border: none;
  border-radius: 4px;
  text-align: center;
}

.btn-primary {
  background-color: var(--primary);
  color: var(--white);
  transition: 0.5s;
}

.btn-primary:hover {
  background-color: var(--primary-hover);
  transition: 0.5s;
}

@media (max-width: 1130px) {
  html,
  body {
    font-size: 60%;
  }

  .about-container img {
    width: 50%;
    height: 100%;
  }

  .about-container p {
    margin: 20px 20px 20px 0;
  }
}

@media (max-width: 956px) {
  .about-content img {
    margin-bottom: 80px;
    width: 70%;
    height: auto;
  }

  .about-content p {
    margin: 20px 20px 20px 0;
  }
}

@media (max-width: 768px) {
  html,
  body {
    font-size: 50%;
  }

  .about-content img {
    width: 80%;
    height: auto;

    order: -1;
  }

  .about-content {
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .btn {
    text-align: center;
    width: 100%;
  }
}

@media (max-width: 425px) {
  .about-content img {
    width: 100%;
    height: auto;
  }
}
