.home-container h1 {
  text-transform: uppercase;
  font-size: 3.2rem;
  margin: 15px 0;
}

.home-container h3 {
  text-transform: none;
  font-size: 1.6rem;
  color: var(--gray);
  font-weight: 400;
}

.home-container {
  display: flex;
  width: 90%;
  max-width: 1200px;

  margin: 100px auto;
}

.home-container img {
  width: 512px;
  height: 100%;
}

.home-content {
  text-align: center;
  margin: 20px auto;
  margin-top: auto;
  margin-bottom: auto;
}

.icon {
  cursor: pointer;
  font-size: 3.2rem;
  color: var(--primary) !important;
  align-items: center;
  justify-content: center;
  transition: 0.5s;
  text-align: center;
  margin: 0 10px;
}

.icon:hover {
  color: var(--primary-hover) !important;
  transition: 0.5s;
}
