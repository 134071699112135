.exp-container {
  width: 90%;
  max-width: 1200px;
  margin: 100px auto;
}

.exp-content {
  display: flex;
  justify-content: space-between;

  width: 100%;
}

.exp-text h1 {
  font-size: 3.2rem;
  text-transform: uppercase;

  padding-left: 10px;
  margin-bottom: 15px;

  border-left: 4px solid var(--primary);
}

.exp-text p {
  font-size: 1.6rem;
  max-width: 500px;
  margin: 20px 0;
  line-height: 2.4rem;
}

.exp-content img {
  width: 512px;
  height: 100%;
}

.exp-text {
  margin: auto 0;
  text-align: justify;
}

.exp-projects {
  margin-top: 80px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
  grid-gap: 20px;
}

.github-link {
  color: var(--primary);
  font-weight: bold;
}

.card {
  color: var(--white);
  border-radius: 4px;
  margin: 0 auto;
  background-color: var(--primary);
  width: 100%;
  height: auto;
}

.card-body {
  padding: 20px;
  height: 100%;
}

.card h1 {
  font-size: 2rem;
  padding: 10px;
}

.card p {
  font-size: 1.4rem;
  padding: 10px;
}

.card-buttons {
  font-size: 2.4rem;
  cursor: pointer;
}

.card-button {
  margin: 0 auto;
}

.exp-container .btn {
  justify-content: center;
  align-items: center;
  padding: 1.5rem;
  display: inline-block;
  text-decoration: none;
  font-size: 1.6rem;
  text-transform: uppercase;
  font-weight: bold;
  width: auto;
  height: 50px;
  border: none;
  border-radius: 4px;
  text-align: center;
  margin: 10px;
}

.btn-secundary {
  background-color: var(--dark-primary);
  color: var(--white);
  transition: 0.5s;
}

.btn-secundary:hover {
  background-color: var(--dark-primary-hover);
  transition: 0.5s;
}

@media (max-width: 1130px) {
  html,
  body {
    font-size: 60%;
  }

  .exp-content img {
    width: 50%;
    height: 100%;
  }

  .exp-content p {
    margin: 20px 20px 20px 0;
  }
}

@media (max-width: 956px) {
  .exp-content img {
    margin-bottom: 80px;
    width: 70%;
    height: auto;
  }

  .exp-content p {
    margin: 20px 20px 20px 0;
  }
}

@media (max-width: 768px) {
  html,
  body {
    font-size: 50%;
  }

  .exp-content img {
    width: 80%;
    height: auto;

    order: -1;
  }

  .exp-content {
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .exp-projects {
    margin: 0 auto;
    width: 90%;
    margin-top: 80px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    grid-gap: 20px;
  }
}

@media (max-width: 525px) {
  .exp-content img {
    width: 100%;
    height: auto;
  }
  .exp-projects {
    width: 100%;
  }
}

@media (max-width: 375px) {
  .exp-projects {
    margin: 0 auto;
    width: 90%;
    margin-top: 80px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
    grid-gap: 20px;
  }

  .exp-container .btn {
    width: 100%;
    margin: 10px 0;
  }
}
