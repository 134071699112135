.navbar {
  background-color: var(--gray);
  height: 80px;
  display: flex;
  align-content: center;
  align-items: center;
  text-align: center;
  position: sticky;
  top: 0;
  z-index: 999;
}

.navbar-container {
  display: flex;
  justify-content: center;
  height: 80px;
}

.container {
  width: 100%;
  max-width: 1300px;
  margin-right: auto;
  margin-left: auto;
  z-index: 1;
  padding-right: 50px;
  padding-left: 50px;
  text-transform: uppercase;
}

.navbar-logo {
  color: var(--white);
  justify-self: start;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-items: center;
}

.navbar-icon {
  margin-right: 0.5rem;
  text-transform: uppercase;
  font-size: 2rem;
  font-weight: bold;
}

.menu-icon {
  color: var(--white);
  cursor: pointer;
  display: none;
}

.nav-menu {
  display: flex;
  list-style: none;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.nav-item {
  height: 80px;
  border-bottom: 4px solid transparent;
  transition: all 0.5s ease;
}

.nav-item:hover {
  border-bottom: 4px solid var(--primary);
  transition: all 0.5s ease;
}

.nav-link:hover {
  color: var(--primary);
  transition: all 0.5 ease;
}

.nav-link {
  font-size: 1.6rem;
  display: flex;
  color: var(--white);
  text-decoration: none;
  align-items: center;
  padding: 0.8rem 1.6rem;
  height: 100%;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  /* Responsive Navbar */
  .nav-menu {
    display: flex;
    /* Change de items direction to column  */
    flex-direction: column;
    width: 100%;
    height: 90vh;
    position: absolute;
    /* Hide the menu */
    left: -100%;
    top: 80px;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background-color: var(--gray);
    /* Make the menu appear */
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .nav-item {
    transition: none;
  }

  .nav-item:hover {
    border: none;
    transition: none;
  }

  .nav-link {
    width: 100%;
    text-align: center;
    display: table;
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    transform: translate(-100%, 60%);
    font-size: 2.8rem;
    margin: 0 auto;
  }

  .fa-times {
    font-size: 2rem;
    transition: all 0.5s ease;
  }

  .fa-bars {
    transition: all 0.5s ease;
  }
}
