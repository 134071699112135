* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
}

html,
body {
  font-size: 62.5%;
  color: #222;
  background-color: var(--white);
}
